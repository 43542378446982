html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  line-height: 1.5;
}


html {
  width: 100%;

  display: flex;
  flex-direction: column;
}