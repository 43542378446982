@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap);
html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  line-height: 1.5;
}


html {
  width: 100%;

  display: flex;
  flex-direction: column;
}
/* @font-face {
  font-family: 'Pixel';
  src: url('./fonts/8-bit-hud.svg#8-bit-hud') format('svg'),
       url('./fonts/8-bit-hud.ttf') format('truetype'),
       url('./fonts/8-bit-hud.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pixel';
  src: url('./fonts/8-bit-hud.eot');
  src: url('./fonts/8-bit-hud.eot?#iefix') format('embedded-opentype'),
       url('./fonts/8-bit-hud.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

a:visited,
a{
  color:inherit;
}

body{
  font-family: 'PT Sans', sans-serif;
}
